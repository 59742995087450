import React from "react";
import { graphql, navigate } from "gatsby";
import PostListing from "../components/PostListing";
//import Paginator from '../components/nPostListing/paginator.jsx';
import Paginator from 'react-paginate';
import { range } from 'lodash';
import Layout from '../components/layout'

export default class PostsTemplate extends React.Component {
  render() {
    //const { slug } = this.props.pageContext;
    //const postNode = this.props.data.markdownRemark;
    //const post = postNode.frontmatter;
    const { data, pageContext } = this.props
    const { totalCount } = data.allMarkdownRemark
    const { group, index, pageCount } = pageContext
    // page index to path
    const paginatorChange = ({ selected }) => {console.log(index, selected);
      navigate(`/posts/${selected > 0 ? selected + 1 : ''}`)}
    const paginator = (<div className='paginator'><Paginator
      pageCount={ pageCount }
      pageRangeDisplayed={ pageCount }
      marginPagesDisplayed={ 3 }
      initialPage={ index - 1 }
      onPageChange={ paginatorChange }
      /></div>)
    return (
      <Layout location={this.props.location}>
        <article className='page'>
          <h1>Posts</h1>
          <PostListing postEdges={ group } />
          { (pageCount > 1) ? paginator : null }
        </article>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query PostsuPage {
    allMarkdownRemark {
      totalCount
    }
  }
`;
